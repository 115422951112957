<script>
import Layout from "@/router/layouts/courses";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Courses: "Cursos",
      Buy: "Comprar",
    },
    es: {
      Courses: "Cursos",
      Buy: "Comprar",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      category: {
        errored: false,
        id: this.$route.params.id,
        name: "CARREGANDO...",
      },

      courses: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      },

      confirm: {
        modal: false,
        loading: false,
        course: null,
      },
    };
  },
  methods: {
    getCategory() {
      api
        .get("courses/category/" + this.category.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.category.name = response.data.category.name;

            this.getCourses();
          }
        })
        .catch((error) => {
          this.category.errored = error;
        });
    },
    getCourses() {
      this.courses.loading = true;
      this.courses.list = null;

      api
        .get("courses/categories/" + this.category.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.courses.list = response.data.list;
            this.courses.loading = false;
          } else {
            this.courses.list = null;
          }
        })
        .catch((error) => {
          this.courses.errored = error;
          this.courses.loading = false;
        })
        .finally(() => {
          this.courses.loading = false;
        });
    },
    confirmBuy(slug) {
      this.confirm.modal = true;

      api.get("courses/" + slug).then((response) => {
        if (response.data.status == "success") {
          this.confirm.course = response.data.course;
        }
      });
    },
    submitBuy(slug) {
      this.confirm.loading = true;

      api.post("courses", {
        slug: slug
      }).then((response) => {
        if (response.data.status == "success") {
          this.confirm.course = response.data.course;
          this.confirm.modal = false;

          this.$router.replace("/courses/orders/" + response.data.order.id);
        }

        this.confirm.loading = false;
      });
    },
  },
  mounted() {
    this.getCategory();
  },
  watch: {
    "$route.params.id": function (v) {
      this.category.id = v;
      this.category.name = "CARREGANDO...";
      this.courses.loading = true;
      this.courses.list = null;

      this.getCategory();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ this.category.name }}</h4>
        </div>
      </div>
    </div>
    <div v-if="courses.loading" class="text-center">
      <b-spinner
        small
        class="ml-2 align-middle"
        variant="white"
        role="status"
      ></b-spinner>
    </div>
    <div
      v-else-if="!courses.list || (courses.list && courses.list.length == 0)"
      class="card"
    >
      <div class="card-body">
        <div class="text-center py-5">
          <i class="far fa-sad-cry font-size-24"></i><br />
          ainda não temos nenhum curso disponível nesta categoria
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div
        class="col-md-4 col-xl-4"
        v-for="(course, id) in courses.list"
        :key="id"
      >
        <div
          v-if="course.cover"
          class="card-header bg-soft-light rounded-top p-0"
        >
          <img class="course-cover rounded-top" :src="course.cover" />
        </div>
        <div v-else class="card-header bg-soft-light rounded-top p-0">
          <img
            class="course-cover rounded-top"
            :src="
              'https://ui-avatars.com/api/?background=EEEFF2&color=fff&name=' +
              course.name +
              '&length=2&font-size=0.2&size=500'
            "
          />
        </div>
        <div class="card bg-soft-light p-2">
          <div class="card-body text-dark p-4">
            <h4 class="text-dark font-weight-bold col-md-9 p-0 mb-4">
              {{ course.name }}
            </h4>
            <div v-html="course.description" class="mb-4"></div>

            <div
              v-if="course.student == 'no'"
              class="d-flex justify-content-between align-self-center"
            >
              <div class="course-registration">
                <button
                  class="btn btn-default btn-lg"
                  v-on:click="confirmBuy(course.slug)"
                >
                  Matricular
                </button>
              </div>
              <div class="course-value text-right">
                <small class="text-uppercase">Valor da Matrícula</small><br />
                <h5>{{ course.value.registration | currency }}</h5>
              </div>
            </div>
            <div
              v-else
              class="bg-white rounded p-2 px-3 text-center text-uppercase font-size-12"
            >
              Você já está matriculado neste curso
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="confirm.modal" :title="t('Confirmar Matrícula')" centered>
      <div v-if="!confirm.course" class="text-center">
        <b-spinner
          v-if="confirm.loading"
          small
          class="ml-2 align-middle"
          variant="default"
          role="status"
        ></b-spinner>
      </div>
      <b-form v-else @submit.prevent="submitBuy(confirm.course.slug)" class="text-center">
          <div
            v-if="confirm.course.cover"
            class="card-header bg-soft-light rounded-top p-0"
          >
            <img class="course-cover rounded-top" :src="confirm.course.cover" />
          </div>
          <div v-else class="card-header bg-soft-light rounded-top p-0">
            <img
              class="course-cover rounded-top"
              :src="
                'https://ui-avatars.com/api/?background=EEEFF2&color=fff&name=' +
                confirm.course.name +
                '&length=2&font-size=0.2&size=500'
              "
            />
          </div>
          <h5 class="my-4">
            Deseja confirma a matrícula do curso de <strong>{{ confirm.course.name }}</strong> no valor de <strong>{{ confirm.course.value.registration | currency }}</strong>?
          </h5>
          <b-button
            :disabled="confirm.loading == true"
            type="submit"
            variant="default"
          >
            Confirmar
            <b-spinner
              v-if="confirm.loading"
              small
              class="ml-2 align-middle"
              variant="white"
              role="status"
            ></b-spinner>
          </b-button>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>

<style scoped>
.course .card.bg-soft-dark {
  background-color: #e6e6e6 !important;
}
.course:hover .card.bg-soft-dark {
  background-color: #ddd !important;
}
.course-cover {
  object-fit: cover;
  aspect-ratio: 1 / 1;
  width: 100%;
}
.course-value {
  line-height: 12px;
}
</style>
